import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../../AppActions';
import {prependTwoZero} from '../../../Utils/TimeUtil';
import HeroBannerSection from './HeroBannerSection';
import AnnouncementSection from './AnnouncementSection';
import MemberServiceSection from './MemberServiceSection';
import PublicationSection from './PublicationSection';
import HumanCareSection from './HumanCareSection';
import TbaCloudFixedButton from './TbaCloudFixedButton';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import DataJson from '../../../../data.json';

const todayMS = new Date(
  `${new Date().getFullYear()}-${prependTwoZero(
    new Date().getMonth() + 1,
  )}-${prependTwoZero(new Date().getDate())}`,
).getTime();

const entries = [
  {
    cover:
      'https://tba-revtel2-com-prod.s3.ap-northeast-1.amazonaws.com/cover-1-1701684576',
    title: '臺灣律師學院',
    desp: '臺灣律師學院課程報名、重要通知',
    route: '/law-service?group=lawyer-school',
  },
  {
    cover:
      'https://tba-revtel2-com-prod.s3.ap-northeast-1.amazonaws.com/cover-2-1701684590',
    title: '律師法',
    desp: '律師法新法DM、律師法新法QA、說明會資料與說明會影片',
    route: '/law-service?group=lawyer-law',
  },
  {
    cover:
      'https://tba-revtel2-com-prod.s3.ap-northeast-1.amazonaws.com/cover-3-1701684602',
    title: '洗錢防制法',
    desp: '防制洗錢相關法規及連結專區',
    route: '/law-service?group=anti-money',
  },
  {
    cover:
      'https://tba-revtel2-com-prod.s3.ap-northeast-1.amazonaws.com/cover-4-1701684613',
    title: '前會計人員舞弊事件專區',
    desp: '前會計人員舞弊事件專區',
    route: '/fraud',
  },
];

function Landing(props) {
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';
  const {landing} = props;

  const [importants, setImportants] = React.useState(null);
  const [newspapers, setNewspapers] = React.useState(null);
  const [courses, setCourses] = React.useState(null);
  const [activities, setActivities] = React.useState(null);
  const [jobs, setJobs] = React.useState(null);
  const [rooms, setRooms] = React.useState(null);
  const [magazines, setMagazines] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);

      try {
        const mapIds = (allResps) => {
          const mappedResults = {};
          for (const k in allResps) {
            const resp = allResps[k];
            mappedResults[k] = {
              ...resp,
              results: resp.results.map((r) => {
                let rr = {...r};
                rr.id = r._id.$oid;
                return rr;
              }),
            };
          }
          return mappedResults;
        };

        const {
          importants_resp,
          newspapers_resp,
          courses_resp,
          activities_resp,
          jobs_resp,
          rooms_resp,
          magazines_resp,
        } = mapIds(await ApiUtil.req(`${DataJson.apiHost}/tba/index`));

        const job_revoke_records = jobs_resp.results.filter((r) => {
          const startTimeMS = new Date(r.data.Start_Time).getTime();
          const endTimeMs = startTimeMS + 30 * 24 * 60 * 60 * 1000;
          return endTimeMs < todayMS;
        });

        const room_revoke_records = rooms_resp.results.filter((r) => {
          const startTimeMS = new Date(r.data.Start_Time).getTime();
          const endTimeMs = startTimeMS + 30 * 24 * 60 * 60 * 1000;
          return endTimeMs < todayMS;
        });

        setImportants(importants_resp.results);
        setNewspapers(newspapers_resp.results);
        setCourses(
          courses_resp.results.sort(
            (a, b) =>
              new Date(
                b.session && b.session.date ? b.session.date : undefined,
              ).getTime() -
              new Date(
                a.session && a.session.date ? a.session.date : undefined,
              ).getTime(),
          ),
        );
        setActivities(
          activities_resp.results.sort(
            (a, b) =>
              new Date(
                b.session && b.session.date ? b.session.date : undefined,
              ).getTime() -
              new Date(
                a.session && a.session.date ? a.session.date : undefined,
              ).getTime(),
          ),
        );
        setJobs(
          jobs_resp.results
            .filter((r) => {
              const revokeIdx = job_revoke_records.findIndex(
                (rr) => rr.id === r.id,
              );
              return revokeIdx === -1;
            })
            .map((r) => ({...r, ...r.data})),
        );
        setRooms(
          rooms_resp.results
            .filter((r) => {
              const revokeIdx = room_revoke_records.findIndex(
                (rr) => rr.id === r.id,
              );
              return revokeIdx === -1;
            })
            .map((r) => ({...r, ...r.data})),
        );
        setMagazines(magazines_resp.results);

        AppActions.setLoading(false);
      } catch (err) {
        console.warn(err);
        AppActions.setLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    <Wrapper mobile={mobile}>
      <HeroBannerSection landing={landing} />
      <AnnouncementSection
        landing={landing}
        importants={importants}
        newspapers={newspapers}
      />
      <MemberServiceSection
        landing={landing}
        courses={courses}
        activities={activities}
        jobs={jobs}
        rooms={rooms}
      />
      <PublicationSection landing={landing} magazines={magazines} />
      <HumanCareSection landing={landing} entries={entries} />

      <TbaCloudFixedButton />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: ${(props) =>
    props.mobile ? `var(--topRwdNavBarHeight)` : `var(--topNavBarHeight)`};
`;

export default Landing;
