import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../../AppActions';

function PublicationSection(props) {
  const {landing, magazines} = props;

  return (
    <Wrapper>
      <div className="container">
        <h2>出版線上</h2>
        <div className="items-container">
          <div
            className="item"
            onClick={() => AppActions.navigate('https://dissent.tba.org.tw/')}>
            <img
              alt="cover"
              src={
                'https://tba-revtel2-com-prod.s3.ap-northeast-1.amazonaws.com/messageImage_1714371821967-1714373814'
              }
            />
            <div className="name">在野法潮 最新選集</div>
          </div>
          {magazines?.map((item, index) => (
            <div
              key={`item-${index}`}
              className="item"
              onClick={() =>
                AppActions.navigate(
                  '/law-service/?group=online&category=magazine',
                )
              }>
              <img alt="cover" src={item.thumbnail} />
              <div className="name">
                {item.title}
                <div>第 {item.period} 期</div>
              </div>
            </div>
          ))}
        </div>
        <div className="button-container">
          <Button
            className="see-more"
            onClick={() =>
              AppActions.navigate(
                '/law-service/?group=online&category=magazine',
              )
            }>
            更多出版
          </Button>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  padding: 40px;
  background-color: #646464;

  & > .container {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;

    & > h2 {
      margin-bottom: 25px;
      text-align: center;
      font-weight: 600;
      font-size: 1.5rem;
      color: #ffffff;
    }

    & > div.items-container {
      margin-bottom: 45px;

      display: flex;
      flex-wrap: wrap;

      & > .item {
        flex: 1;

        cursor: pointer;

        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px;

        & > img {
          width: 180px;
          height: 240px;
          object-fit: cover;
        }

        & > .name {
          margin-top: 10px;
          max-width: 180px;
          width: 100%;
          text-align: center;
          color: #ffffff;
          font-weight: 400;
          font-size: 1.05rem;
        }
      }
    }

    & > div.button-container {
      margin: 0 auto;
      display: flex;
      justify-content: center;

      & > .see-more {
        border: none;
        font-weight: bold;
        font-size: 1rem;
        background-color: #646464;
        color: #ffffff;
      }
    }
  }
`;

export default PublicationSection;
