import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../../AppActions';
import {formatDateStr} from '../../../Utils/TimeUtil';
import {ArrowRight} from '@styled-icons/bootstrap/ArrowRight';

function getDayDisplay(dateStr) {
  let day = new Date(dateStr).getDay();
  if (day === 0) {
    return '日';
  } else if (day === 1) {
    return '一';
  } else if (day === 2) {
    return '二';
  } else if (day === 3) {
    return '三';
  } else if (day === 4) {
    return '四';
  } else if (day === 5) {
    return '五';
  } else if (day === 6) {
    return '六';
  }
}

function MemberServiceSection(props) {
  const {landing, courses, activities, jobs, rooms} = props;
  const [selectedTab, setSelectedTab] = React.useState(0);

  return (
    <Wrapper selectedTab={selectedTab}>
      <div className="container">
        <h2>會員服務</h2>

        <div className="tab-header">
          <div className="item index-0" onClick={() => setSelectedTab(0)}>
            課程
          </div>
          <div className="item index-1" onClick={() => setSelectedTab(1)}>
            活動
          </div>
          <div className="item index-2" onClick={() => setSelectedTab(2)}>
            求才
          </div>
          <div className="item index-3" onClick={() => setSelectedTab(3)}>
            辦公室
          </div>
        </div>

        {selectedTab === 0 && (
          <div className="tab-container">
            {courses?.map((item, index) => (
              <div
                key={`tab-0-item-${index}`}
                className="item"
                onClick={() => AppActions.navigate(`/product?id=${item.id}`)}>
                <div className="date">
                  {item.session &&
                  Object.keys(item.session).length > 0 &&
                  item.session.date
                    ? `${item.session.date} (${getDayDisplay(
                        item.session.date,
                      )})`
                    : '線上自由安排'}
                </div>
                <div className="name">{item.name}</div>
                <ArrowRight size={16} />
              </div>
            ))}
            <div className="item" style={{height: 0}} />
            <div className="item" style={{height: 0}} />
            <div className="item" style={{height: 0}} />
            <div className="item" style={{height: 0}} />
          </div>
        )}
        {selectedTab === 1 && (
          <div className="tab-container">
            {activities?.map((item, index) => (
              <div
                key={`tab-1-item-${index}`}
                className="item"
                onClick={() => AppActions.navigate(`/product?id=${item.id}`)}>
                <div className="date">
                  {item.session
                    ? `${item.session.date} (${getDayDisplay(
                        item.session.date,
                      )})`
                    : ''}
                </div>
                <div className="name">{item.name}</div>
                <ArrowRight size={16} />
              </div>
            ))}
            <div className="item" style={{height: 0}} />
            <div className="item" style={{height: 0}} />
            <div className="item" style={{height: 0}} />
            <div className="item" style={{height: 0}} />
          </div>
        )}
        {selectedTab === 2 && (
          <div className="tab-container">
            {jobs?.map((item, index) => (
              <div
                key={`tab-2-item-${index}`}
                className="item"
                onClick={() =>
                  AppActions.navigate(`/find?id=${item.id}&type=job`)
                }>
                <div className="date">
                  {formatDateStr(new Date(item.Start_Time))} (
                  {getDayDisplay(item.Start_Time)})
                </div>
                <div className="title">
                  {Array.isArray(item.openings) &&
                    item.openings.map((opening, index) => (
                      <div key={`opening-${index}`}>{opening.name}</div>
                    ))}
                </div>
                <div className="name">{item.name}</div>
                <ArrowRight size={16} />
              </div>
            ))}
            <div className="item" style={{height: 0}} />
            <div className="item" style={{height: 0}} />
            <div className="item" style={{height: 0}} />
            <div className="item" style={{height: 0}} />
          </div>
        )}
        {selectedTab === 3 && (
          <div className="tab-container">
            {rooms?.map((item, index) => (
              <div
                key={`tab-3-item-${index}`}
                className="item"
                onClick={() =>
                  AppActions.navigate(`/find?id=${item.id}&type=room`)
                }>
                <div className="date">
                  {formatDateStr(new Date(item.Start_Time))} (
                  {getDayDisplay(item.Start_Time)})
                </div>
                <div className="title">{item.name}</div>
                <div className="name">{item.address_firm}</div>
                <ArrowRight size={16} />
              </div>
            ))}
            <div className="item" style={{height: 0}} />
            <div className="item" style={{height: 0}} />
            <div className="item" style={{height: 0}} />
            <div className="item" style={{height: 0}} />
          </div>
        )}

        <div className="button-container">
          <Button
            className="see-more"
            onClick={() => {
              if (selectedTab === 0) {
                AppActions.navigate('/products?cat=課程&sort=-session.date');
              } else if (selectedTab === 1) {
                AppActions.navigate('/products?cat=活動&sort=-session.date');
              } else if (selectedTab === 2) {
                AppActions.navigate('/finds?type=job');
              } else if (selectedTab === 3) {
                AppActions.navigate('/finds?type=room');
              }
            }}>
            更多{selectedTab === 0 && '課程'}
            {selectedTab === 1 && '活動'}
            {selectedTab === 2 && '求才'}
            {selectedTab === 3 && '辦公室'}
          </Button>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  background-color: #efefef;
  padding: 40px;

  & > .container {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;

    & > h2 {
      margin-bottom: 25px;
      text-align: center;
      font-weight: 600;
      font-size: 1.5rem;
    }

    & > div.tab-header {
      display: flex;

      & > div.item {
        flex: 1;

        cursor: pointer;
        border-bottom: 5px solid #969696;
        padding: 5px;

        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;
      }

      ${(props) => {
        if (props.selectedTab === 0) {
          return `& > div.index-0 {  border-bottom: 5px solid var(--secondColor); }`;
        } else if (props.selectedTab === 1) {
          return `& > div.index-1 {  border-bottom: 5px solid var(--secondColor); }`;
        } else if (props.selectedTab === 2) {
          return `& > div.index-2 {  border-bottom: 5px solid var(--secondColor); }`;
        } else if (props.selectedTab === 3) {
          return `& > div.index-3 {  border-bottom: 5px solid var(--secondColor); }`;
        }
      }}
    }

    & > div.tab-container {
      margin-bottom: 30px;

      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      & > div.item {
        flex: 1;

        padding: 20px;
        cursor: pointer;
        min-width: 200px;
        width: 100%;

        & > .date {
          margin-bottom: 5px;
          font-weight: 600;
          font-size: 1.25rem;
          color: var(--primaryColor);
        }

        & > .title {
          margin-bottom: 5px;
          font-weight: 600;
          font-size: 1.15rem;
          color: var(--secondColor);
        }

        & > .name {
          margin-bottom: 20px;
          font-size: 1.15rem;
        }
      }
    }

    & > div.button-container {
      margin: 0 auto;
      display: flex;
      justify-content: center;

      & > .see-more {
        border: none;
        font-weight: bold;
        font-size: 1rem;
        background-color: #efefef;
      }
    }
  }
`;

export default MemberServiceSection;
