import React from 'react';
import styled from 'styled-components';
import * as AppActions from '../../../AppActions';

function TbaCloudFixedButton(props) {
  return (
    <Wrapper
      onClick={() =>
        AppActions.navigate(
          '/products/?cat=進修課程-影音課程&sort=-session.date',
        )
      }>
      <img
        alt="tba_cloud"
        src="/images/tba_cloud_logo.jpg"
        className="cloud-logo"
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 90px;
  height: 90px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;

  position: fixed;
  right: 20px;
  bottom: 20px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
  }

  & > .cloud-logo {
    padding: 10px;
    width: 80px;
    height: 80px;
    object-fix: cover;
  }
`;

export default TbaCloudFixedButton;
