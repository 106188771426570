import React from 'react';
import styled from 'styled-components';
import * as AppActions from '../../../AppActions';

function HumanCareSection(props) {
  const {landing, entries} = props;

  return (
    <Wrapper>
      <div className="title">人文關懷、社會參與</div>
      <div className="container">
        {entries.map((item, index) => {
          return (
            <div
              key={index}
              className="item"
              onClick={() => AppActions.navigate(item.route)}>
              <img alt="cover" className="cover" src={item.cover} />
              <div className="content">
                <div className="title">{item.title}</div>
                <div className="desp">{item.desp}</div>
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 60px 10px;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;

  & > .title {
    margin: 0 auto 25px auto;
    max-width: 900px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
  }

  & > .container {
    margin: 0 auto;
    max-width: 1150px;
    width: 100%;
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .item {
    margin: 20px;
    max-width: 224px;
    width: 100%;
    cursor: pointer;

    & > .cover {
      width: 100%;
      height: auto;
    }

    & > .content {
      padding: 15px 5px;

      & > .title {
        margin-bottom: 15px;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
      }

      & > .desp {
        max-width: 210px;
        text-align: center;
        font-size: 14px;
      }
    }
  }
`;

export default HumanCareSection;
