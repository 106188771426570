import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../../AppActions';
import Carousel, {SWIPE_STATE} from '../../../Components/Carousel';

function HeroBannerSection(props) {
  const {landing} = props;
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  return (
    <Wrapper className="hero-banner-section">
      <div className="container">
        {(() => {
          if (dimension.innerWidth >= 1920 || dimension.innerWidth >= 500) {
            // size 21:9

            const width =
              dimension.innerWidth >= 1920 ? 1920 : dimension.innerWidth;
            const height =
              dimension.innerWidth >= 1920
                ? (1920 / 21) * 9
                : (dimension.innerWidth / 21) * 9;

            return (
              <Carousel
                width={width}
                height={height}
                data={landing.custom_layout.hero_banner_section.images || []}
                renderPrev={null}
                renderNext={null}
                disableSwipe={true}
                renderItem={({item, dimension, swipeStateRef}) => (
                  <SlideItem
                    item={item}
                    swipeStateRef={swipeStateRef}
                    width={width}
                    height={height}
                  />
                )}
                renderDots={({currIdx, goToIndex}) => (
                  <Dots
                    currIdx={currIdx}
                    goToIndex={goToIndex}
                    dataLength={
                      (landing.custom_layout.hero_banner_section.images || [])
                        .length
                    }
                  />
                )}
              />
            );
          } else {
            // size 1:1

            const width = dimension.innerWidth;
            const height = dimension.innerWidth;

            return (
              <Carousel
                width={width}
                height={height}
                data={
                  landing.custom_layout.hero_banner_section.images_mobile || []
                }
                renderPrev={null}
                renderNext={null}
                disableSwipe={true}
                renderItem={({item, dimension, swipeStateRef}) => (
                  <SlideItem
                    width={width}
                    height={height}
                    item={item}
                    dimension={dimension}
                    swipeStateRef={swipeStateRef}
                  />
                )}
                renderDots={({currIdx, goToIndex}) => (
                  <Dots
                    currIdx={currIdx}
                    goToIndex={goToIndex}
                    dataLength={
                      (
                        landing.custom_layout.hero_banner_section
                          .images_mobile || []
                      ).length
                    }
                  />
                )}
              />
            );
          }
        })()}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .container {
    overflow: hidden;

    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
  }
`;

function SlideItem(props) {
  const {item, width, height, swipeStateRef} = props;

  return (
    <SlideWrapper
      width={width}
      height={height}
      href={item.path}
      draggable={false}
      rel="noreferrer"
      target="_blank">
      <img
        src={item.image}
        width={width}
        height={height}
        draggable={false}
        alt="slide-item"
        onClick={(e) => {
          if (swipeStateRef.current === SWIPE_STATE.swipe) {
            e.preventDefault();
            return;
          }

          if (!item.path) {
            e.preventDefault();
            return;
          }

          if (item.path[0] === '/') {
            e.preventDefault();
            AppActions.navigate(item.path);
          }
        }}
      />
    </SlideWrapper>
  );
}

const SlideWrapper = styled.a`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: relative;
  display: block;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    object-fit: cover;
    overflow: hidden;
    user-select: none;
  }
`;

function Dots(props) {
  const {currIdx, goToIndex, dataLength} = props;

  return (
    <DotsWrapper>
      {Array.from(Array(dataLength).keys()).map((_, idx) => (
        <Dot
          key={idx}
          selected={idx === currIdx}
          onClick={() => goToIndex(idx)}
        />
      ))}
    </DotsWrapper>
  );
}

const DotsWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px;

  display: flex;
  justify-content: center;
`;

const Dot = styled.div`
  margin: 10px 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px 0px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? 'var(--primaryColor)' : '#ffffff'};
`;

export default HeroBannerSection;
