import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {PinAngleFill} from '@styled-icons/bootstrap/PinAngleFill';
import * as AppActions from '../../../AppActions';
import {formatDateStr} from '../../../Utils/TimeUtil';

function formatReleaseDateStr(str) {
  return (str || '').slice(0, 10);
}

function AnnouncementSection(props) {
  const {landing, importants, newspapers} = props;
  const [selectedTab, setSelectedTab] = React.useState(0);

  return (
    <Wrapper selectedTab={selectedTab}>
      <div className="container">
        <h2>公告訊息</h2>

        <div className="tab-header">
          <div className="item index-0" onClick={() => setSelectedTab(0)}>
            重大訊息
          </div>
          <div className="item index-1" onClick={() => setSelectedTab(1)}>
            新聞稿
          </div>
        </div>

        {selectedTab === 0 && (
          <div className="tab-container">
            {importants?.map((item, index) => (
              <div
                key={`tab-0-item-${index}`}
                className="item"
                onClick={() =>
                  AppActions.navigate(`/announcement?id=${item.id}`)
                }>
                <div className="name">
                  {item.is_pin && (
                    <PinAngleFill
                      size={26}
                      color="#EA0000"
                      style={{paddingRight: 5}}
                    />
                  )}
                  {item.Title}
                </div>
                <div className="date">
                  {(item.Release_date &&
                    formatReleaseDateStr(item.Release_date)) ||
                    formatDateStr(new Date(item.created))}
                </div>
              </div>
            ))}
          </div>
        )}
        {selectedTab === 1 && (
          <div className="tab-container">
            {newspapers?.map((item, index) => (
              <div
                key={`tab-1-item-${index}`}
                className="item"
                onClick={() =>
                  AppActions.navigate(`/announcement?id=${item.id}`)
                }>
                <div className="name">
                  {item.is_pin && (
                    <PinAngleFill
                      size={26}
                      color="#EA0000"
                      style={{paddingRight: 5}}
                    />
                  )}
                  {item.Title}
                </div>
                <div className="date">
                  {(item.Release_date &&
                    formatReleaseDateStr(item.Release_date)) ||
                    formatDateStr(new Date(item.created))}
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="button-container">
          <Button
            className="see-more"
            onClick={() => {
              if (selectedTab === 0) {
                AppActions.navigate('/announcements?type=important');
              } else if (selectedTab === 1) {
                AppActions.navigate('/announcements?type=newspaper');
              }
            }}>
            更多{selectedTab === 0 ? '重大訊息' : '新聞稿'}
          </Button>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  padding: 40px;

  & > .container {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;

    & > h2 {
      margin-bottom: 25px;
      text-align: center;
      font-weight: 600;
      font-size: 1.5rem;
    }

    & > div.tab-header {
      display: flex;

      & > div.item {
        flex: 1;

        cursor: pointer;
        border-bottom: 5px solid #969696;
        padding: 5px;

        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;
      }

      ${(props) =>
        props.selectedTab === 0
          ? `& > div.index-0 {  border-bottom: 5px solid var(--secondColor); }`
          : `& > div.index-1 {  border-bottom: 5px solid var(--secondColor); }`}
    }

    & > div.tab-container {
      margin-bottom: 30px;
      min-height: 245px;

      & > div.item {
        border-bottom: 3px solid #c8c8c8;
        padding: 12px 0px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        & > .name {
          flex: 1;
          margin-right: 5px;
          font-size: 1.05rem;
        }

        & > .date {
          width: 110px;
          font-size: 1.05rem;
        }

        &:hover {
          color: var(--primaryColor);
        }
      }
    }

    & > div.button-container {
      margin: 0 auto;
      display: flex;
      justify-content: center;

      & > .see-more {
        border: none;
        font-weight: bold;
        font-size: 1rem;
        background-color: #ffffff;
      }
    }
  }
`;

export default AnnouncementSection;
